<template>
  <el-dialog :close-on-click-modal="false" destroy-on-close :title="edit?'编辑':'新增'" v-model="dialogFormVisible" width="750px">
    <el-scrollbar :maxHight="contentStyleObj">
      <el-form ref="dataForm" :rules="rules" :model="temp" label-position="right" label-width="120px" :inline="true" size="small">
        <el-form-item label="账期:" prop="period">
          <el-date-picker @change="changePeiod" v-model="temp.period" type="month" placeholder="选择账期" style="width:150px" :disabled="edit"></el-date-picker>
          <span v-if="!edit">（默认上一账期）</span>
        </el-form-item>
        <el-form-item label="身份证号:" prop="idNo" :label-width="edit?'120px':'80px'">
          <el-input v-model="temp.idNo" placeholder="请输入身份证号" style="width:150px;" :disabled="edit"/>
        </el-form-item>
        <el-form-item label="累计收入:" prop="salary">
          <el-input v-model="temp.salary" placeholder="请输入累计收入" style="width:150px;"  @change="computedYbtse()"/>
        </el-form-item>
        <el-form-item label="累计免税收入:" prop="ljmssr">
          <el-input v-model="temp.ljmssr" placeholder="请输入累计免税收入" style="width:150px;"  @change="computedYbtse()"/>
        </el-form-item>
          <el-form-item label="累计减除费用:" prop="deductOther">
          <el-input v-model="temp.deductOther" placeholder="请输入累计减除费用" style="width:150px;"  @change="computedYbtse()"/>
        </el-form-item>
        <el-form-item label="累计专项扣除:" prop="deductSocial">
          <el-input v-model="temp.deductSocial" placeholder="请输入累计专项扣除" style="width:150px;"  @change="computedYbtse()"/>
        </el-form-item>
        <el-form-item label="累计其他扣除:">
          <el-input v-model="temp.qtkc" placeholder="请输入累计其他扣除" style="width:150px;"  @change="computedYbtse()"/>
        </el-form-item>
        <el-form-item label="子女教育:" prop="childrenEducation">
          <el-input v-model="temp.childrenEducation" placeholder="请输入子女教育" style="width:150px;"  @change="computedYbtse()"/>
        </el-form-item>

        <el-form-item label="继续教育:" prop="continuingEducation">
          <el-input v-model="temp.continuingEducation" placeholder="请输入继续教育" style="width:150px;"  @change="computedYbtse()"/>
        </el-form-item>

        <!-- <el-form-item label="大病医疗:" prop="seriousIllness">
          <el-input v-model="temp.seriousIllness" style="width:150px;"/>
        </el-form-item> -->

        <el-form-item label="住房贷款:" prop="housingLoan">
          <el-input v-model="temp.housingLoan" placeholder="请输入住房贷款" style="width:150px;"  @change="computedYbtse()"/>
        </el-form-item>

        <el-form-item label="住房租金:" prop="housingRent">
          <el-input v-model="temp.housingRent" placeholder="请输入住房租金" style="width:150px;"  @change="computedYbtse()"/>
        </el-form-item>

        <el-form-item label="赡养老人:" prop="support">
          <el-input v-model="temp.support" placeholder="请输入赡养老人" style="width:150px;"  @change="computedYbtse()"/>
        </el-form-item>
        <el-form-item label="婴幼儿照护费用:" prop="babyReduction">
          <el-input v-model="temp.babyReduction" placeholder="请输入婴幼儿照护费用" style="width:150px;"  @change="computedYbtse()"/>
        </el-form-item>
        <el-form-item label="个人养老金:" prop="personalPension">
          <el-input v-model="temp.personalPension" placeholder="请输入个人养老金" style="width:150px;"  @change="computedYbtse()"/>
        </el-form-item>
        <el-form-item label="累计应纳所得额:" prop="amount">
          <el-input v-model="temp.amount" placeholder="请输入累计应纳所得额" style="width:150px;" disabled/>
        </el-form-item>
        <el-form-item label="累计应交税款:" prop="tax">
          <el-input v-model="temp.tax" placeholder="请输入累计应交税款" style="width:150px;" disabled/>
        </el-form-item>
        <el-form-item label="累计已交税款:" prop="taxEnd">
          <el-input v-model="temp.taxEnd" placeholder="请输入累计已交税款" style="width:150px;"/>
        </el-form-item>
      </el-form>
    </el-scrollbar>
    <template #footer>
      <div class="dialog-footer">
        <el-button size="small" @click="dialogFormVisible = false">取消</el-button>
        <el-button size="small" type="primary" @click="createData()">确定</el-button>
      </div>
    </template>
    </el-dialog>
</template>

<script>
import { parseTime } from '@/utils'
import { salaryInitImport, updateEaSalayPeriod } from '@/api/salary.js'
export default {
  name: 'addInformation',
  props: {
    period: {
      type: String,
      default: "",
    },
    comIdInit: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      list: [],
      dialogFormVisible: false,
      temp: {
        period:this.period,
        // periodf:undefined,
        idNo: '',
        salary: undefined,
        ljmssr: undefined,
        deductSocial:undefined,
        deductOther:undefined,
        childrenEducation: undefined,
        continuingEducation: undefined,
        // seriousIllness: undefined,
        housingLoan: undefined,
        housingRent: undefined,
        support:undefined,
        amount:undefined,
        babyReduction: undefined,
        tax:undefined,
        taxEnd:undefined,
        qtkc:undefined
      },
      rules: {
        period: [{ required: true, message: '请选择账期', trigger: 'change' }],
        idNo: [{ required: true, message: '请输入身份证号', trigger: 'change' }],
        salary: [{ required: true, message: '请输入累计收入', trigger: 'blur' }],
        ljmssr: [{ required: false, message: '请输入累计免税收入', trigger: 'blur' }],
        deductOther: [{ required: false, message: '请输入累计减除费用', trigger: 'blur' }],
        deductSocial: [{ required: false, message: '请输入累计专项扣除', trigger: 'blur' }],
        childrenEducation: [{ required: false, message: '请输入子女教育金额', trigger: 'blur' }],
        continuingEducation: [{ required: false, message: '请输入继续教育金额', trigger: 'blur' }],
        // seriousIllness: [{ required: false, message: '请输入大病医疗金额', trigger: 'blur' }],
        housingLoan: [{ required: false, message: '请输入住房贷款金额', trigger: 'blur' }],
        housingRent: [{ required: false, message: '请输入住房租金金额', trigger: 'blur' }],
        support: [{ required: false, message: '请输入赡养老人金额', trigger: 'blur' }],
        babyReduction: [{ required: false, message: '请输入累计婴幼儿照护费用', trigger: 'blur' }],
        personalPension: [{ required: false, message: '请输入个人养老金', trigger: 'blur' }],
        amount: [{ required: false, message: '请输入累计应纳所得额', trigger: 'blur' }],
        tax: [{ required: false, message: '请输入累计应交税款', trigger: 'blur' }],
        taxEnd: [{ required: false, message: '请输入累计已交税款', trigger: 'blur' }]
      },
      delall: [],
      contentStyleObj:{}, //高度变化
      comId:0,
      edit:false,
    };
  },
  mounted(){
    this.contentStyleObj = this.$getHeight(260)
  },
  methods: {
    //弹窗
    openSalary(e){
      this.initializeTemp()
      if(e){
        this.temp = Object.assign({},e);
        this.edit = true
      }
      this.dialogFormVisible = true
    },
    changePeiod(){
      if(this.temp.periodf){
        this.temp.period = parseTime(this.temp.periodf, "{y}{m}");
      }
    },
    initializeTemp(){
      this.edit = false
      this.temp = {
        period:this.period,
        // periodf:undefined,
        idNo: '',
        salary: 0,
        ljmssr: 0,
        deductSocial:0,
        deductOther:0,
        childrenEducation: 0,
        continuingEducation: 0,
        // seriousIllness: 0,
        housingLoan: 0,
        housingRent: 0,
        support:0,
        amount:0,
        babyReduction: 0,
        tax:0,
        taxEnd:0,
        qtkc:0
      }
    },
    resetTemp() {
      this.temp = {
        period:this.period,
        idNo: this.temp.idNo,
        salary: Number(this.temp.salary),
        ljmssr: Number(this.temp.ljmssr),
        deductSocial:Number(this.temp.deductSocial),
        deductOther:Number(this.temp.deductOther),
        childrenEducation: Number(this.temp.childrenEducation),
        continuingEducation: Number(this.temp.continuingEducation),
        // seriousIllness: Number(this.temp.seriousIllness),
        housingLoan: Number(this.temp.housingLoan),
        housingRent: Number(this.temp.housingRent),
        support:Number(this.temp.support),
        amount:Number(this.temp.amount),
        babyReduction: Number(this.temp.babyReduction),
        personalPension:Number(this.temp.personalPension),
        tax:Number(this.temp.tax),
        taxEnd:Number(this.temp.taxEnd),
        qtkc:Number(this.temp.qtkc),
        id:this.temp.id?this.temp.id:null,
        employeeId:this.temp.employeeId?this.temp.employeeId:null
      }
    },
    createData() {
      this.$refs['dataForm'].validate((valid) => {
        if(valid) {
          this.comId = this.comIdInit ? this.comIdInit : this.$store.getters['user/comInfo'].comId*1
          this.resetTemp()
          if(this.edit){
            this.temp.comId = this.comId
            updateEaSalayPeriod(this.temp).then(res=>{
              if(res.data.msg == 'success'){
                this.$message({
                message: '修改成功',
                type: 'success'
              });
              this.dialogFormVisible = false
              this.$emit("success")
              }
            })
          }else{
            let param = {
              items:[this.temp],
              period : this.temp.period,
              comId: this.comId,
            }
            salaryInitImport(param).then(res => {
            if(res.data.msg == "success"){
              this.$message({
                message: '新增成功',
                type: 'success'
              });
              this.dialogFormVisible = false
              this.$emit("success")
            }
           })
          } 
        }
      })
    },
    computedYbtse(){
      //累计应纳税所得额
      let sum = 0
      sum = Number(this.temp.salary) - Number(this.temp.ljmssr) - Number(this.temp.deductSocial) - Number(this.temp.deductOther) - Number(this.temp.qtkc) - Number(this.temp.childrenEducation) - Number(this.temp.continuingEducation) - Number(this.temp.housingLoan) - Number(this.temp.housingRent) - Number(this.temp.support) - Number(this.temp.babyReduction) - Number(this.temp.personalPension)
      if(sum < 0){
        this.temp.amount = 0
      }else{
        this.temp.amount = Number(sum.toFixed(2))
      }
      let tax = 0
      if(sum < 36000){
        tax = sum * 0.03
      }else if(sum > 36000 && sum < 144000){
        tax = 1080 + (sum - 36000) * 0.1 - 2520
      }else if(sum > 144000 && sum < 300000){
        tax = 5280 + (sum - 144000) * 0.2 - 16920
      }else if(sum > 300000 && sum <420000){
        tax = 9360 + (sum - 300000) * 0.25 - 31920
      }else if(sum > 420000 && sum < 660000){
        tax = 18480 + (sum - 420000) * 0.3 - 52920
      }else if(sum > 660000 && sum < 960000){
        tax = 32880 + (sum - 660000) * 0.35 - 85920
      }else if(sum > 960000){
        tax = 57580 + (sum - 960000) * 0.45 - 181920
      }
      if(tax < 0){
        this.temp.tax = 0
      }else{
        this.temp.tax = Number(tax.toFixed(2))
      }
    }
  }
}
</script>

<style lang="scss" scoped>
</style>