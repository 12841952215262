<template>
  <div class="app-container">
    <!-- 顶部按钮 -->
    <div class="top_btns clearfix">
      <div class="fl">
        <el-date-picker :disabled="!periodStatus" @change="getList" v-model="listQuery.period" value-format="YYYYMM" format="YYYY-MM" type="month" placeholder="选择月" size="small" style="width:120px"></el-date-picker>
        <span
          v-if="periodStatus"
          style="font-size: 13px;color:var(--themeColor,#17a2b8);margin-left:10px;position: relative;top:2px"
        >
          <span>*温馨提示：导入成功后,切换上一账期进行查看</span>
        </span>
      </div>
      <div class="right_btns fr">
        <!-- <qzf-video vid="4467be53741d6cbdf4348ffe36ff5130_4"></qzf-video> -->
        <colSetting name="累计数据" btnName="显示列" @arrangeInfo="getInfo" @load="loadingV" :from="type"/>
        <!-- <el-button  @click="handleCreate" size="small" type="success">添加</el-button> -->
        <el-dropdown split-button type="primary" @click="handleCreate" size="small" style="margin:0 10px" :hide-on-click="false" v-if="$buttonStatus('xc_ljsj_xz')">
          <i class="iconfont icon-jiahao"></i> 添加
          <template #dropdown>
            <el-dropdown-menu>
              <el-dropdown-item>
                <span size="small" @click="openCom">导入</span>
              </el-dropdown-item>
            </el-dropdown-menu>
          </template>
        </el-dropdown>
        <qzf-button jz="false" plain button_code="xc_ljsj_sc" type="danger" size="small" @success='delallClick'   >
          <el-icon><Delete /></el-icon> <span  >删除</span>
        </qzf-button>
        <el-button @click="daochu()" type="primary" size="small" icon="Folder" :disabled="!$buttonStatus('xc_ljsj_dc')">
          导出
        </el-button>
        <el-button
          @click="dayin()"
          type="success"
          size="small"
          icon="Folder"
          style="margin-left: 10px;"
          :disabled="!$buttonStatus('xc_ljsj_dy')"
        >
          打印
        </el-button>
      </div>
    </div>
    <!-- 顶部按钮 end -->
    <!-- 内容 -->
    <el-table stripe :height="contentStyleObj" @selection-change="handleSelectionChange" v-loading="listLoading" :header-cell-style="{background: '#eee',color:'#333','font-size': '13px'}" :data="tableData" border style="width: 100%" show-summary>
      <template #empty>
        <el-empty :image-size="150" description="没有数据"></el-empty>
      </template>
      <el-table-column align="center" type="selection" width="55"></el-table-column>
      <el-table-column type="index" fixed label="序号" align="center" width="60" v-if="arrangeInfo.includes('序号')"></el-table-column>
      <el-table-column label="姓名"  min-width="100" fixed v-if="arrangeInfo.includes('姓名')">
        <template #default="{row}">
          {{row.name}}
        </template>
      </el-table-column>
      <el-table-column label="证件号码" min-width="200" fixed v-if="arrangeInfo.includes('证件号码')">
        <template #default="{row}">
          {{row.idNo}}
        </template>
      </el-table-column>
       <el-table-column label="累计收入" prop="salary" min-width="100" v-if="arrangeInfo.includes('累计收入')">
        <template #default="{row}">
          {{row.salary}}
        </template>
      </el-table-column>

      <el-table-column label="累计免税收入" prop="ljmssr" min-width="120" v-if="arrangeInfo.includes('累计免税收入')">
        <template #default="{row}">
          {{row.ljmssr}}
        </template>
      </el-table-column>

      <el-table-column label="累计减除费用" prop="deductOther" min-width="120" v-if="arrangeInfo.includes('累计减除费用')">
        <template #default="{row}">
          {{row.deductOther}}
        </template>
      </el-table-column>
      <el-table-column label="累计专项扣除" prop="deductSocial" min-width="120" v-if="arrangeInfo.includes('累计专项扣除')">
        <template #default="{row}">
          {{row.deductSocial}}
        </template>
      </el-table-column>
      <el-table-column label="累计其他扣除" min-width="120" v-if="arrangeInfo.includes('累计其他扣除')">
        <template #default="{row}">
          {{row.qtkc}}
        </template>
      </el-table-column>
      <el-table-column label="子女教育" prop="childrenEducation" min-width="100" v-if="arrangeInfo.includes('子女教育')">
        <template #default="{row}">
          {{row.childrenEducation}}
        </template>
      </el-table-column>
      <el-table-column label="继续教育" prop="continuingEducation" min-width="100" v-if="arrangeInfo.includes('继续教育')">
        <template #default="{row}">
          {{row.continuingEducation}}
        </template>
      </el-table-column>
      <!-- <el-table-column label="大病医疗" prop="seriousIllness" min-width="100" v-if="arrangeInfo.includes('大病医疗')">
        <template #default="{row}">
          {{row.seriousIllness}}
        </template>
      </el-table-column> -->
      <el-table-column label="住房贷款利息" prop="housingLoan" min-width="120" v-if="arrangeInfo.includes('住房贷款利息')">
        <template #default="{row}">
          {{row.housingLoan}}
        </template>
      </el-table-column>
      <el-table-column label="住房租金" prop="housingRent" min-width="100" v-if="arrangeInfo.includes('住房租金')">
        <template #default="{row}">
         {{row.housingRent}}
        </template>
      </el-table-column>
      <el-table-column label="赡养老人" prop="support" min-width="100" v-if="arrangeInfo.includes('赡养老人')">
        <template #default="{row}">
          {{row.support}}
        </template>
      </el-table-column>
      <el-table-column label="婴幼儿照护费用" prop="babyReduction" min-width="120" v-if="arrangeInfo.includes('婴幼儿照护费用')">
        <template #default="{row}">
          {{row.babyReduction}}
        </template>
      </el-table-column>
      <el-table-column label="个人养老金" prop="personalPension" min-width="120" v-if="arrangeInfo.includes('个人养老金')">
        <template #default="{row}">
          {{row.personalPension}}
        </template>
      </el-table-column>
      <el-table-column label="累计应纳所得额" prop="amount" min-width="120" v-if="arrangeInfo.includes('累计应纳所得额')">
        <template #default="{row}">
          {{row.amount}}
        </template>
      </el-table-column>
      <el-table-column label="累计应交税款" prop="tax" min-width="120" v-if="arrangeInfo.includes('累计应交税款')">
        <template #default="{row}">
          {{row.tax}}
        </template>
      </el-table-column>
      <el-table-column label="累计已交税款" prop="taxEnd" min-width="120" v-if="arrangeInfo.includes('累计已交税款')">
        <template #default="{row}">
          {{row.taxEnd}}
        </template>
      </el-table-column>
      <el-table-column label="操作" align="center" min-width="120" class-name="sizeBor small-padding fixed-width top_right_btns">
          <template #default="{row}">
            <el-button size="small"  type="primary" plain icon="Edit" @click="edit(row)">编辑</el-button>
          </template>
        </el-table-column>
    </el-table>
    <!-- 内容 end -->
    <qzf-import-back :hideButton="true" ref="importBck" @success="getList()" from="税款计算" :comId="this.listQuery.comId" :liPeriod="this.listQuery.period"></qzf-import-back>
    <addInformation ref="addInformation" @success="getList" :period="this.listQuery.period" :comIdInit="this.listQuery.comId"></addInformation>
  </div>
</template>

<script>
import addInformation from './addInformation.vue'
import { getEaSalayPeriod,salaryInitDel } from '@/api/salary.js'
import { wbVoucherFxOther} from "@/api/export";
export default {
  name:'information',
  components:{ addInformation },
  watch: {
    $route(to){
      if(to.name == "information"){
        this.getList()
      }
    }
  },
  data() {
    return{
      tableData: [],
      listQuery: {
        period: '',
        comId: 0,
        periodf: '',
      },
      delall:[],
      ids:[],
      listLoading: true,
      arrangeInfo:[
        "序号",
        "姓名",
        "证件号码",
        "累计收入",
        "累计免税收入",
        "累计减除费用",
        "累计专项扣除",
        "累计其他扣除",
        "子女教育",
        "继续教育",
        // "大病医疗",
        "住房贷款利息",
        "住房租金",
        "赡养老人",
        "婴幼儿照护费用",
        "累计应纳所得额",
        "累计应交税款",
        "累计已交税款"
      ],
      type:'',
      periodStatus:this.$store.getters['user/comInfo'].period,
    }
  },
  created() {
    // 获取表格高度
    this.contentStyleObj=this.$getHeight(170)
    window.addEventListener('resize', this.getHeight);
    // this.getList()
  },
  methods: {
    loadingV(){
      this.listLoading = true
    },
    getInfo(e){
      this.arrangeInfo = e
      this.getList()
    },
    handleCreate() {
      // if(this.$checkSettleStatus() != ""){
      //   //this.$message.error(this.$checkSettleStatus())
      //   return 
      // }
      this.$refs.addInformation.openSalary()
    },
    handleSelectionChange(e){
      this.delall = e
      this.ids = []
      this.delall.map(v =>{
        this.ids.push(v.id)
      })
    },
    //批量删除
    delallClick(){
      if(this.$checkSettleStatus()) return
      if(this.delall.length == 0){
          this.$qzfMessage("请至少选择一个",1)
        }else{
          this.$confirm("确定删除选项吗？", "提示", {
            confirmButtonText: "确定",
            cancelButtonText: "取消",
            type: "warning"
          }).then(() => {
            let idss=[]
            this.delall.map(item=>{
              idss.push({id:item.id})
            })
            salaryInitDel(idss).then(res=>{
              if(res.data.msg == "success"){
                this.$message.success('删除成功')
              }else{
                this.$message.error('删除失败')
              }
              this.getList()
            })
          }); 
        }
    },
    //列表展示
    getList() {
      this.listLoading = true
      if(this.$store.getters["commons/params"].informationPeriod){
        this.listQuery.period = this.$store.getters["commons/params"].informationPeriod
        this.listQuery.comId = this.$store.getters["commons/params"].informationComId
        this.type = ''
      }else if(this.listQuery.period){
        this.listQuery.period
        this.type = 'book'
      }else{
        this.listQuery.comId = this.$store.getters['user/comInfo'].comId*1
        this.listQuery.period =  this.$store.getters['user/comInfo'].period
        this.type = 'book'
      }
      if(this.listQuery.period == ""){
        return
      }  
      getEaSalayPeriod(this.listQuery).then(res => {
        this.listLoading = false
        this.tableData = res.data.data.list?res.data.data.list:[];
        this.tableData.map(v=>{
          v.amount = v.amount < 0?0:v.amount
          v.tax = v.tax < 0?0:v.tax
        })
      })
    },
    openCom(){
      this.$refs.importBck.handImport()
    },
    //编辑
    edit(row){
      this.$refs.addInformation.openSalary(row)
    },
    daochu() {
      let param = {
        comIds:[this.listQuery.comId],
        outType:"excel",
        exportType:"salary_accumulated",
        period:this.listQuery.period,
        query:{
          ids:this.ids
        }
      }
      wbVoucherFxOther(param).then((res) => {
        if (res.data.msg == "success") {
          window.open(res.data.data.url);
        }
      });
    },
    dayin() {
      let param = {
        comIds:[this.listQuery.comId],
        outType:"pdf",
        exportType:"salary_accumulated",
        period:this.listQuery.period,
        query:{
          ids:this.ids
        }
      }
      wbVoucherFxOther(param).then((res) => {
        if (res.data.msg == "success") {
          window.open(res.data.data.url);
        }
      });
    },
  }
}
</script>

<style lang="scss" scoped>
 
.el-table {
  margin-top: 10px;
}
</style>